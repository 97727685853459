import {
  RootRoute,
  Route,
  Router,
  Outlet,
  redirect
} from "@tanstack/react-router";
import { useWalletContext } from "./context/WalletContext";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import Gateway from "./pages/Gateway";
import GatewayOverview from "./pages/GatewayOverview";
import GatewayLocation from "./pages/GatewayLocation";
import OnboardGW from "./pages/OnboardGW";
import ConnectGW from "./pages/ConnectGW";
import Aside from "./components/layout/Aside";
import { NavigationProvider, useNavigation } from "./context/NavigationContext";
import Header from "./components/Header";
import { useRef, useEffect, useState } from "react";
import DebugPage from "./pages/Debug";
import GatewayDevices from "./pages/GatewayDevices";
import GatewayNetwork from "./pages/GatewayNetwork";
import OnboardingWizard from "./pages/OnboardingWizard";
import CreateGw from "./pages/CreateGw";
import SwipeableContent from "./components/SwipeableContent";
import GatewayNotifications from './pages/GatewayNotifications';
import CloudOnboardingWizard from './pages/CloudOnboardingWizard';
import GatewayStatusCheck from './components/GatewayStatusCheck';
import EchoPage from './pages/EchoPage';

// Root component as a proper React component
const RootComponent = () => {
  const { isConnected } = useWalletContext();
  const { navOpen, toggleNav } = useNavigation();
  const asideRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (window.innerWidth < 1024 && navOpen && asideRef.current && !asideRef.current.contains(event.target as Node)) {
        toggleNav();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [navOpen, toggleNav]);
  
  if (!isConnected) {
    return (
      <div className="flex h-screen bg-[#1d1d1d]">
        <main className="flex-1 overflow-y-auto">
          <Outlet />
        </main>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen flex-col bg-[#1d1d1d]">
      <Header />
      <div className="relative flex flex-1 w-full overflow-hidden">
        {navOpen && (
          <div className="fixed inset-0 z-30 bg-black/50 lg:hidden" />
        )}
        <div
          ref={asideRef}
          className={`${
            navOpen ? "translate-x-0" : "-translate-x-full"
          } fixed inset-y-0 left-0 z-40 w-full max-w-[24rem] overflow-hidden border-r border-gray-600/80 bg-[#1d1d1d] transition-transform duration-300 lg:relative lg:w-96 lg:translate-x-0`}
        >
          <Aside />
        </div>
        <main className="flex-1 min-w-0 overflow-auto">
          <div className="min-h-full">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

// Auth check loader
const authLoader = async () => {
  // We can access the context here because it's during route loading
  const walletContext = document.querySelector('[data-wallet-context]');
  const isConnected = walletContext?.getAttribute('data-is-connected') === 'true';
  
  if (!isConnected) {
    throw redirect({
      to: '/login',
      replace: true
    });
  }
  return {};
};

const rootRoute = new RootRoute({
  component: () => (
    <NavigationProvider>
      <RootComponent />
    </NavigationProvider>
  )
});

const indexRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/",
  beforeLoad: authLoader,
  component: HomePage
});

const loginRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/login",
  component: LoginPage,
});

const createGwRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/create-gw",
  beforeLoad: authLoader,
  component: CreateGw
});

const onboardGwRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/onboard-gw",
  beforeLoad: authLoader,
  component: OnboardGW
});

const connectGwRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/connect-gw",
  beforeLoad: authLoader,
  component: ConnectGW
});

const gatewayRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/gateway/$serial",
  beforeLoad: authLoader,
  component: Gateway
});

// Create a GatewayLayout component for gateway-specific routes
const GatewayLayout = () => {
  const [enterDirection, setEnterDirection] = useState<'left' | 'right'>('right');

  return (
    <SwipeableContent 
      enterDirection={enterDirection} 
      onDirectionChange={setEnterDirection}
    >
      <Outlet />
    </SwipeableContent>
  );
};

// Create a gateway parent route
const gatewayLayoutRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'gateway/$gatewayId',
  component: GatewayLayout
});

// Update gateway routes to be children of gatewayLayoutRoute
export const gatewayOverviewRoute = new Route({
  getParentRoute: () => gatewayLayoutRoute,
  path: '/',
  beforeLoad: authLoader,
  component: GatewayOverview
});

const gatewayLocationRoute = new Route({
  getParentRoute: () => gatewayLayoutRoute,
  path: '/location',
  beforeLoad: authLoader,
  component: GatewayLocation
});

export const gatewayDevicesRoute = new Route({
  getParentRoute: () => gatewayLayoutRoute,
  path: '/devices',
  beforeLoad: authLoader,
  component: GatewayDevices
});

const gatewayNetworkRoute = new Route({
  getParentRoute: () => gatewayLayoutRoute,
  path: '/network',
  beforeLoad: authLoader,
  component: GatewayNetwork
});

const debugRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/debug",
  beforeLoad: authLoader,
  component: DebugPage
});

const onboardingWizardRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/onboarding-wizard",
  beforeLoad: authLoader,
  component: OnboardingWizard
});

// Add the cloud onboarding wizard route
const cloudOnboardingWizardRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/onboarding/cloud",
  beforeLoad: authLoader,
  component: CloudOnboardingWizard
});

// Add the gateway status check route
const gatewayStatusCheckRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/gateway-status",
  beforeLoad: authLoader,
  component: GatewayStatusCheck
});

// Add the echo route
const echoRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/echo",
  beforeLoad: authLoader,
  component: EchoPage
});

// Update route tree
const routeTree = rootRoute.addChildren([
  indexRoute,
  loginRoute,
  createGwRoute,
  onboardGwRoute,
  connectGwRoute,
  gatewayRoute,
  gatewayLayoutRoute.addChildren([
    gatewayOverviewRoute,
    gatewayLocationRoute,
    gatewayDevicesRoute,
    gatewayNetworkRoute,
    new Route({
      getParentRoute: () => gatewayLayoutRoute,
      path: '/notifications',
      beforeLoad: authLoader,
      component: GatewayNotifications
    })
  ]),
  debugRoute,
  onboardingWizardRoute,
  cloudOnboardingWizardRoute,
  echoRoute,
  gatewayStatusCheckRoute
]);

export const router = new Router({ 
  routeTree,
  defaultPreload: 'intent'
});

export default router; 