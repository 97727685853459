import NavItem from "./NavItem";
import GatewayList from "../GatewayList";
import { useNavigation } from "../../context/NavigationContext";
import { 
  HomeIcon, 
  // WrenchScrewdriverIcon, 
  RocketLaunchIcon,
  // SignalIcon, 
  WalletIcon, 
  BeakerIcon,
  // QuestionMarkCircleIcon,
  ArrowTopRightOnSquareIcon,
  CloudIcon,
  WrenchScrewdriverIcon
} from "@heroicons/react/24/solid";
import { FaDiscord, FaBook, FaHome, FaMobileAlt } from 'react-icons/fa';
import { useState, useEffect } from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useMatchRoute } from "@tanstack/react-router";
import { useGateways } from "../../context/GatewayContext";
import { InformationCircleIcon, ChatBubbleLeftRightIcon } from "@heroicons/react/24/outline";

const Aside = () => {
  const { toggleNav } = useNavigation();
  const [expandedGatewayIds, setExpandedGatewayIds] = useState<Set<string>>(new Set());
  const matchRoute = useMatchRoute();
  const { gateways } = useGateways();

  useEffect(() => {
    if (!gateways) return;

    // Find gateway with active sub-route and ensure it stays expanded
    const activeGatewayId = gateways.find(gateway => {
      const isOverviewActive = matchRoute({ to: `/gateway/${gateway.id}`, fuzzy: false });
      const isLocationActive = matchRoute({ to: `/gateway/${gateway.id}/location`, fuzzy: false });
      const isDevicesActive = matchRoute({ to: `/gateway/${gateway.id}/devices`, fuzzy: false });
      return isOverviewActive || isLocationActive || isDevicesActive;
    })?.id;

    if (activeGatewayId) {
      setExpandedGatewayIds(new Set([activeGatewayId]));
    }
  }, [matchRoute, gateways]);

  const handleMainNavClick = () => {
    if (window.innerWidth < 1024) toggleNav();
  };

  const ExternalLink = ({ href, icon: Icon, children }: { href: string; icon: any; children: React.ReactNode }) => (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="group flex items-center justify-between rounded-lg p-2 text-gray-300 hover:bg-gray-600/50 transition-colors"
    >
      <div className="flex items-center">
        <Icon className="h-5 w-5 mr-2" />
        <span>{children}</span>
      </div>
      <ArrowTopRightOnSquareIcon className="h-4 w-4 text-gray-400" />
    </a>
  );

  return (
    <>
      <style>
        {`
          /* Hide scrollbar for mobile */
          @media (max-width: 768px) {
            .nav-sidebar::-webkit-scrollbar {
              display: none;
            }
            .nav-sidebar {
              -ms-overflow-style: none;
              scrollbar-width: none;
            }
          }
          /* Custom scrollbar for desktop */
          @media (min-width: 769px) {
            .nav-sidebar::-webkit-scrollbar {
              width: 6px;
            }
            .nav-sidebar::-webkit-scrollbar-track {
              background: rgba(31, 41, 55, 0.5);
              border-radius: 3px;
            }
            .nav-sidebar::-webkit-scrollbar-thumb {
              background: rgba(75, 85, 99, 0.5);
              border-radius: 3px;
            }
            .nav-sidebar::-webkit-scrollbar-thumb:hover {
              background: rgba(107, 114, 128, 0.5);
            }
          }
        `}
      </style>
      <nav className="nav-sidebar h-full overflow-y-auto px-4 pt-16 pb-6 lg:pt-4">
        <div className="flex flex-col space-y-2 mb-6">
          <NavItem 
            title="Home" 
            path="/" 
            icon={<HomeIcon className="h-5 w-5 mr-2" />}
            onClick={handleMainNavClick}
          />
          <div className="flex h-10">
            <div className="flex w-full items-center rounded-lg p-2 text-gray-300">
              <WalletIcon className="h-5 w-5 mr-2" />
              <span className="mr-3">Wallet:</span>
              <WalletMultiButton />
            </div>
          </div>
          <NavItem 
            title="Onboard Hardware Gateway" 
            path="/onboarding-wizard" 
            icon={<RocketLaunchIcon className="h-5 w-5 mr-2" />}
            onClick={handleMainNavClick}
          />
          <NavItem 
            title="Onboard Cloud Gateway" 
            path="/onboarding/cloud" 
            icon={<CloudIcon className="h-5 w-5 mr-2" />}
            onClick={handleMainNavClick}
          />
          {/* <NavItem 
            title="Onboard New Gateway" 
            path="/onboard-gw" 
            icon={WrenchScrewdriverIcon} 
            onClick={handleMainNavClick}
          /> */}
          
        </div>

        <div className="border-t border-gray-600/80 pt-6">
          <GatewayList 
            onNavigate={handleMainNavClick}
            expandedGatewayIds={expandedGatewayIds}
            setExpandedGatewayIds={setExpandedGatewayIds}
          />
        </div>

        <div className="border-t border-gray-600/80 pt-6 mt-6">
          <h2 className="mb-2 px-2 text-xs font-semibold uppercase tracking-wider text-gray-400">
            Tools
          </h2>
          <NavItem 
            title="Gateway Information" 
            path="/connect-gw" 
            icon={<InformationCircleIcon className="h-5 w-5 mr-2" />}
            onClick={handleMainNavClick}
          />
          <NavItem 
            title="Gateway Status Check" 
            path="/gateway-status" 
            icon={<WrenchScrewdriverIcon className="h-5 w-5 mr-2" />}
            onClick={handleMainNavClick}
          />
        </div>

        <div className="border-t border-gray-600/80 pt-6 mt-6">
          <h2 className="mb-2 px-2 text-xs font-semibold uppercase tracking-wider text-gray-400">
            Links
          </h2>
          <div className="flex flex-col space-y-1">
            <ExternalLink href="https://sourceful.energy" icon={FaHome}>
              Sourceful Energy
            </ExternalLink>
            <ExternalLink href="https://discord.com/invite/srcful" icon={FaDiscord}>
              Discord
            </ExternalLink>
            <ExternalLink href="https://docs.sourceful.energy" icon={FaBook}>
              Documentation
            </ExternalLink>
            <ExternalLink href="https://app.sourceful.energy" icon={FaMobileAlt}>
              Native Mobile App
            </ExternalLink>
            <ExternalLink href="https://support.sourceful.energy" icon={ChatBubbleLeftRightIcon}>
              Support
            </ExternalLink>
          </div>
        </div>

        <div className="border-t border-gray-600/80 pt-6 mt-6">
          <h2 className="mb-2 px-2 text-xs font-semibold uppercase tracking-wider text-gray-400">
            Developer
          </h2>
          <NavItem 
            title="Debug" 
            path="/debug" 
            icon={<BeakerIcon className="h-5 w-5 mr-2" />}
            onClick={handleMainNavClick}
          />
          <NavItem 
            title="Echo" 
            path="/echo" 
            icon={<ChatBubbleLeftRightIcon className="h-5 w-5 mr-2" />}
            onClick={handleMainNavClick}
          />
        </div>
      </nav>
    </>
  );
};

export default Aside;
