import { createContext, useContext, useState, ReactNode, useEffect, useRef } from "react";
import { useWallet, } from "@solana/wallet-adapter-react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import SrcfulMessageProtocolFactory from "../services/backend/SrcfulMessageProtocolFactory";
import { SignedMessage, signSrcfulMessage } from "../messaging/Message";
import { fetchGatewayState } from "../services/backend/SrcfulApiRequests";
import { toast } from "react-hot-toast";
import { createClient } from 'graphql-ws';
import { fetchWalletGateways, createGatewaySubscriptionQuery, fetchGatewayDers, DerInfo } from "../services/backend/SrcfulPublicApi";
import GatewayMessageManager from '../services/GatewayMessageManager';

// Storage helper functions
const getStoredClaims = (walletPubkey: string) => {
  // Clear any stored claims from other wallets
  const allKeys = Object.keys(localStorage);
  const gatewayClaimKeys = allKeys.filter(key => key.startsWith('gateway-claims-'));
  
  gatewayClaimKeys.forEach(key => {
    if (key !== `gateway-claims-${walletPubkey}`) {
      localStorage.removeItem(key);
    }
  });

  // Get claims for current wallet
  const stored = localStorage.getItem(`gateway-claims-${walletPubkey}`);
  return stored ? JSON.parse(stored) : {};
};

const storeGatewayClaims = (walletPubkey: string, claims: Record<string, SignedMessage>) => {
  localStorage.setItem(`gateway-claims-${walletPubkey}`, JSON.stringify(claims));
};

export interface Gateway {
  id: string;
  name: string;
  serial: string;
  h3Index: string;
  publicKey: string;
  typeOf: string;
  ownershipClaim?: SignedMessage;
  state?: Record<string, any>;
  ders?: DerInfo[];
}

// Helper to check if a gateway is hardware type
export const isHardwareGateway = (gateway: Gateway): boolean => {
  return gateway.typeOf === "Hardware";
};

interface GatewayContextType {
  gateways: Gateway[];
  activeGateway: Gateway | null;
  setActiveGateway: (gateway: Gateway | null) => void;
  selectedGateway: Gateway | null;
  setSelectedGateway: (gateway: Gateway | null) => void;
  isLoading: boolean;
  error: Error | null;
  fetchGatewayStates: () => void;
  isGatewaysSigned: boolean;
  refreshGateways: () => Promise<void>;
  claimNewGateway: (gatewayId: string) => Promise<void>;
  suppressSigningStep: boolean;
  setSuppressSigningStep: (suppress: boolean) => void;
}

const GatewayContext = createContext<GatewayContextType>({
  gateways: [],
  activeGateway: null,
  setActiveGateway: () => {},
  selectedGateway: null,
  setSelectedGateway: () => {},
  isLoading: false,
  error: null,
  fetchGatewayStates: async () => {},
  isGatewaysSigned: false,
  refreshGateways: async () => {},
  claimNewGateway: async () => {},
  suppressSigningStep: false,
  setSuppressSigningStep: () => {},
});

const wsClient = createClient({
  url: 'wss://api.srcful.dev/',
});

// Add this interface to define the subscription response type
interface ConfigurationDataChangesResponse {
  data?: {
    configurationDataChanges?: {
      data: Record<string, any>;
      subKey: string;
    };
  };
}

// Add deep equality check function
const isEqual = (obj1: any, obj2: any): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
  //return false;
};

export const GatewayProvider = ({ children }: { children: ReactNode }) => {
  const { connected, publicKey, signMessage } = useWallet();
  const [activeGateway, setActiveGateway] = useState<Gateway | null>(null);
  const [selectedGateway, setSelectedGateway] = useState<Gateway | null>(null);
  const queryClient = useQueryClient();
  const [isGatewaysSigned, setIsGatewaysSigned] = useState(false);
  const [suppressSigningStep, setSuppressSigningStep] = useState(false);

  // Reset signed state when wallet disconnects
  useEffect(() => {
    if (!connected) {
      setIsGatewaysSigned(false);
      setActiveGateway(null);
      GatewayMessageManager.getInstance().clearWalletConfig();
    } else if (connected && publicKey && signMessage) {
      GatewayMessageManager.getInstance().setWalletConfig({
        publicKey: publicKey.toString(),
        signMessage
      });
    }
  }, [connected, publicKey, signMessage]);

  // Separate query for gateway list
  const { data: gateways, isLoading, error } = useQuery({
    queryKey: ["gateways", publicKey?.toBase58()],
    queryFn: async () => {
      const gatewayResponses = await fetchWalletGateways(publicKey!.toString());
      
      // Fetch DER info for each gateway
      const gatewaysWithDers = await Promise.all(
        gatewayResponses.map(async (gateway) => {
          try {
            const ders = await fetchGatewayDers(gateway.id);
            return {
              id: gateway.id,
              name: gateway.name,
              serial: gateway.id,
              h3Index: gateway.h3Index,
              publicKey: gateway.publicKey,
              typeOf: gateway.typeOf || "Hardware",
              ders
            };
          } catch (error) {
            console.error(`Failed to fetch DERs for gateway ${gateway.id}:`, error);
            // Return gateway without DER info if fetch fails
            return {
              id: gateway.id,
              name: gateway.name,
              serial: gateway.id,
              h3Index: gateway.h3Index,
              publicKey: gateway.publicKey,
              typeOf: gateway.typeOf || "Hardware"
            };
          }
        })
      );

      return gatewaysWithDers;
    },
    enabled: connected && !!publicKey,
  }) as { data: Gateway[] | undefined, isLoading: boolean, error: Error | null };

  // Load stored claims when wallet connects
  useEffect(() => {
    if (connected && publicKey && gateways) {
      const storedClaims = getStoredClaims(publicKey.toString());
      const updatedGateways = gateways.map(gateway => ({
        ...gateway,
        ownershipClaim: storedClaims[gateway.id]
      }));
      
      // If all gateways have claims, set as signed and fetch states
      const allSigned = updatedGateways.every(gateway => gateway.ownershipClaim);
      if (allSigned) {
        // First update the gateways with claims
        queryClient.setQueryData(["gateways", publicKey.toBase58()], updatedGateways);
        setIsGatewaysSigned(true);

        // Then fetch states for each gateway
        Promise.all(
          updatedGateways.map(async (gateway) => {
            try {
              const state = await fetchGatewayState(gateway.id, gateway.ownershipClaim!);
              return {
                ...gateway,
                state
              };
            } catch (error) {
              console.error(`Failed to fetch state for gateway ${gateway.id}:`, error);
              return gateway;
            }
          })
        ).then((gatewaysWithStates) => {
          queryClient.setQueryData(["gateways", publicKey.toBase58()], gatewaysWithStates);
        });
      }
    }
  }, [connected, publicKey, gateways, queryClient]);

  const { mutate: fetchGatewayStates } = useMutation({
    mutationFn: async () => {
      console.log("Fetching gateway states...");
      if (!publicKey || !signMessage || !gateways || gateways.length === 0) {
        throw new Error("Missing requirements for fetching states");
      }

      // const toastId = toast.loading("Fetching gateway states...");
      try {
        const storedClaims = getStoredClaims(publicKey.toString());
        const needsNewSignature = gateways.some(gateway => 
          !gateway.ownershipClaim && !storedClaims[gateway.id]
        );

        let signedMessage: SignedMessage | null = null;

        console.log("Needs new signature:", needsNewSignature);
        if (needsNewSignature) {
          const gatewayIds = gateways.map(gateway => gateway.id);
          const message = SrcfulMessageProtocolFactory.createClaimOwnershipMessage({
            walletPublicKey: publicKey.toString(),
            gatewayIds,
            expirationDays: 365
          });

          signedMessage = await signSrcfulMessage(signMessage, message);
          if (!signedMessage) {
            // toast.dismiss(toastId);
            throw new Error("Failed to sign message");
          }

          // Store new signature
          const newClaims = { ...storedClaims };
          gateways.forEach(gateway => {
            newClaims[gateway.id] = signedMessage;
          });
          storeGatewayClaims(publicKey.toString(), newClaims);
        }

        // Fetch states and update gateways directly
        const updatedGateways = await Promise.all(
          gateways.map(async (gateway) => {
            try {
              const claimToUse = gateway.ownershipClaim || signedMessage;
              if (!claimToUse) {
                throw new Error("No ownership claim available");
              }

              const state = await fetchGatewayState(gateway.id, claimToUse);
              return {
                ...gateway,
                state,
                ownershipClaim: claimToUse
              };
            } catch (error) {
              console.error(`Failed to fetch state for gateway ${gateway.id}:`, error);
              return gateway; // Keep original gateway on error
            }
          })
        );

        // Update the gateways query data directly
        queryClient.setQueryData(["gateways", publicKey?.toBase58()], updatedGateways);
        setIsGatewaysSigned(true);
        // toast.dismiss(toastId);
        // toast.success("Gateway states updated");
      } catch (error) {
        // toast.dismiss(toastId);
        toast.error("Failed to update gateway states");
        throw error;
      }
    }
  });

  // Add this ref to store current gateways
  const gatewaysRef = useRef<Gateway[]>([]);

  // Update ref when gateways change
  useEffect(() => {
    if (gateways) {
      gatewaysRef.current = gateways;
    }
  }, [gateways]);

  // Modify the subscription useEffect
  useEffect(() => {
    if (!connected || !publicKey || !isGatewaysSigned || !gatewaysRef.current) {
      return;
    }

    const subscriptions = gatewaysRef.current.map(gateway => {
      const claim = gateway.ownershipClaim;
      if (!claim) return null;

      const unsubscribe = wsClient.subscribe(
        {
          query: createGatewaySubscriptionQuery(
            gateway.id,
            claim.message,
            claim.signature
          ),
        },
        {
          next: (response: ConfigurationDataChangesResponse) => {
            const newData = response?.data?.configurationDataChanges?.data;
            const subKey = response?.data?.configurationDataChanges?.subKey;
            console.log(subKey);
            console.log(newData);
            if (newData && subKey === "state") {
              queryClient.setQueryData(
                ["gateways", publicKey.toBase58()],
                (oldData: Gateway[] | undefined) => {
                  if (!oldData) return oldData;
                  return oldData.map(g => {
                    if (g.id === gateway.id) {
                      // Check if state has actually changed
                      if (isEqual(g.state, newData)) {
                        console.log("State hasn't changed");
                        return g; // Return existing gateway if state hasn't changed
                      }
                      return {
                        ...g,
                        state: newData
                      };
                    }
                    return g;
                  });
                }
              );
            }
            // Handle responses for echo messages
            if (subKey === "response" && newData) {
              console.log('Received echo response:', newData);
              console.log('Response type:', typeof newData);
              // Parse the response if it's a string
              const responseData = typeof newData === 'string' ? JSON.parse(newData) : newData;
              console.log('Parsed response data:', responseData);
              GatewayMessageManager.getInstance().handleResponse(responseData);
            }
          },
          error: (error) => {
            console.error(`Subscription error for gateway ${gateway.id}:`, error);
          },
          complete: () => {
            console.log(`Subscription completed for gateway ${gateway.id}`);
          },
        }
      );

      return () => unsubscribe();
    });

    return () => {
      subscriptions.forEach(unsubscribe => unsubscribe?.());
    };
  }, [connected, publicKey, isGatewaysSigned, queryClient]);

  const refreshGateways = async () => {
    console.log("Refreshing gateways...");
    if (!publicKey || !signMessage) {
      throw new Error("Missing publicKey or signMessage");
    }

    const gatewayResponses = await fetchWalletGateways(publicKey.toString());
    const newGateways = gatewayResponses.map((f): Gateway => ({
      id: f.id,
      name: f.name,
      serial: f.id,
      h3Index: f.h3Index,
      publicKey: f.publicKey,
      typeOf: f.typeOf || "Hardware"
    }));
    
    console.log("New gateways:", newGateways);

    // Get existing claims
    const existingClaims = getStoredClaims(publicKey.toString());
    
    // Update the gateways state and wait for it to be reflected
    await queryClient.setQueryData(["gateways", publicKey.toBase58()], newGateways);
    
    // Keep all existing claims, just update isGatewaysSigned flag
    const allSigned = newGateways.every((gateway: Gateway) => existingClaims[gateway.id]);
    setIsGatewaysSigned(allSigned);
  };

  const claimNewGateway = async (gatewayId: string) => {
    if (!publicKey || !signMessage) {
      throw new Error("Wallet not connected");
    }

    const toastId = toast.loading("Claiming gateway...");
    try {
      // Create a new signature for this gateway
      const message = SrcfulMessageProtocolFactory.createClaimOwnershipMessage({
        walletPublicKey: publicKey.toString(),
        gatewayIds: [gatewayId],
        expirationDays: 365
      });

      const signedMessage = await signSrcfulMessage(signMessage, message);
      if (!signedMessage) {
        throw new Error("Failed to sign message");
      }

      // Store the new claim
      const storedClaims = getStoredClaims(publicKey.toString());
      storedClaims[gatewayId] = signedMessage;
      storeGatewayClaims(publicKey.toString(), storedClaims);

      // Fetch the gateway state
      const state = await fetchGatewayState(gatewayId, signedMessage);

      // Update the gateways in the query cache
      queryClient.setQueryData(
        ["gateways", publicKey.toBase58()],
        (oldData: Gateway[] | undefined) => {
          if (!oldData) return oldData;
          return oldData.map(gateway => {
            if (gateway.id === gatewayId) {
              return {
                ...gateway,
                ownershipClaim: signedMessage,
                state
              };
            }
            return gateway;
          });
        }
      );

      toast.success("Gateway claimed successfully");
    } catch (error) {
      console.error("Failed to claim gateway:", error);
      toast.error("Failed to claim gateway");
      throw error;
    } finally {
      toast.dismiss(toastId);
    }
  };

  return (
    <GatewayContext.Provider
      value={{
        gateways: gateways || [],
        activeGateway,
        setActiveGateway,
        selectedGateway,
        setSelectedGateway,
        isLoading,
        error,
        fetchGatewayStates,
        isGatewaysSigned,
        refreshGateways,
        claimNewGateway,
        suppressSigningStep,
        setSuppressSigningStep,
      }}
    >
      {children}
    </GatewayContext.Provider>
  );
};

export const useGateways = () => {
  const context = useContext(GatewayContext);
  if (context === undefined) {
    throw new Error("useGateways must be used within a GatewayProvider");
  }
  return context;
};
