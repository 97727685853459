import { useGateways, isHardwareGateway } from "../context/GatewayContext";
import ContentFramework from "../components/ContentFramework";
import { useWallet } from "@solana/wallet-adapter-react";
import { useParams } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { gatewayOverviewRoute } from "../router";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import InfoRow from "../components/InfoRow";
import { DerInfo } from "../services/backend/SrcfulPublicApi";
import GatewayPageHeader from '../components/GatewayPageHeader';
import { gatewayInfo } from '../constants/infoTexts';
import PageContainer from '../components/layout/PageContainer';
import { GatewayIcon, CloudGatewayIcon } from '../components/icons/icons';
import { ActionButton } from '../components/ui/ActionButton';
import { toast } from "react-hot-toast";
import GatewayMessageManager from '../services/GatewayMessageManager';
import { CircleStackIcon, ClockIcon, CpuChipIcon, BeakerIcon, ArrowPathIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";

const formatLastSeen = (timestamp: number) => {
  const now = Date.now();
  const diff = Math.floor((now - timestamp) / 1000);
  const minutes = Math.floor(diff / 60);
  const date = new Date(timestamp);
  const userLocale = navigator.language;
  const timeStr = date.toLocaleTimeString(userLocale, { hour: '2-digit', minute: '2-digit' });
  
  if (minutes < 1) return `Just now (${timeStr})`;
  if (minutes === 1) return `1 minute ago (${timeStr})`;
  if (minutes < 60) return `${minutes} minutes ago (${timeStr})`;
  
  // If more than 24 hours, show full date
  if (diff > 86400) {
    return date.toLocaleString(userLocale, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  }
  
  // If more than an hour but less than 24 hours
  const hours = Math.floor(minutes / 60);
  return `${hours} hour${hours > 1 ? 's' : ''} ago (${timeStr})`;
};

const formatDerLastSeen = (isoTimestamp: string) => {
  const date = new Date(isoTimestamp);
  const userLocale = navigator.language;
  
  // If more than 24 hours ago, show full date
  const now = Date.now();
  const diff = now - date.getTime();
  
  if (diff > 86400000) { // more than 24 hours
    return date.toLocaleString(userLocale, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  }
  
  // If less than 24 hours, show relative time
  const minutes = Math.floor(diff / 60000);
  const timeStr = date.toLocaleTimeString(userLocale, { hour: '2-digit', minute: '2-digit' });
  
  if (minutes < 1) return `Just now (${timeStr})`;
  if (minutes === 1) return `1 minute ago (${timeStr})`;
  if (minutes < 60) return `${minutes} minutes ago (${timeStr})`;
  
  const hours = Math.floor(minutes / 60);
  return `${hours} hour${hours > 1 ? 's' : ''} ago (${timeStr})`;
};

const formatUptime = (milliseconds: number) => {
  const seconds = Math.floor(milliseconds / 1000);
  const days = Math.floor(seconds / (24 * 60 * 60));
  const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((seconds % (60 * 60)) / 60);

  const parts = [];
  if (days > 0) parts.push(`${days}d`);
  if (hours > 0) parts.push(`${hours}h`);
  if (minutes > 0) parts.push(`${minutes}m`);
  
  // If less than a minute, show seconds
  if (parts.length === 0) {
    return `${Math.floor(seconds)}s`;
  }

  return parts.join(' ');
};

const DerSection = ({ ders }: { ders?: DerInfo[] }) => {
  if (!ders || ders.length === 0) {
    return null;
  }

  return (
    <>
      <div className="h-px bg-gray-200/10 my-8" />
      <div>
        <h2 className="text-base font-bold leading-7">
          Distributed Energy Resources
        </h2>
      </div>
      <div className="mt-5">
        {ders.map((der) => (
          <InfoRow
            key={der.sn}
            label={der.type}
            tooltip={gatewayInfo.der_lastSeen.tooltip}
            value={der.lastSeen}
            displayValue={
              <span className={`${
                new Date(der.lastSeen).getTime() < Date.now() - 15 * 60 * 1000 
                ? 'text-red-500' 
                : ''
              }`}>
                {formatDerLastSeen(der.lastSeen)}
              </span>
            }
          />
        ))}
      </div>
    </>
  );
};

const ReauthenticationSection = ({ gatewayId, publicKey }: { gatewayId: string; publicKey: string | null }) => {
  const [isCreatingSession, setIsCreatingSession] = useState(false);

  const handleReauth = async () => {
    if (!publicKey) {
      toast.error('Please connect your wallet first');
      return;
    }

    setIsCreatingSession(true);
    try {
      const response = await fetch('https://huawei.srcful.dev/gateway_session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ownerWallet: publicKey,
          type: 'reauth_oauth',
          redirect_url: 'https://app.srcful.io',
          gateway_serial: gatewayId
        })
      });

      if (!response.ok) {
        throw new Error('Failed to create Huawei session');
      }

      const session = await response.json();
      
      // Redirect to Huawei OAuth page
      const huaweiOAuthUrl = `https://oauth2.fusionsolar.huawei.com/rest/dp/uidm/oauth2/v1/authorize?response_type=code&client_id=742215509&redirect_uri=https://huawei.srcful.dev/oauth/callback&state=${session.session_id}`;
      window.location.href = huaweiOAuthUrl;
    } catch (error) {
      console.error('Failed to create Huawei session:', error);
      toast.error('Failed to start Huawei authentication');
    } finally {
      setIsCreatingSession(false);
    }
  };

  return (
    <div className="mt-8 space-y-4">
      <div className="flex items-center gap-4">
        <h2 className="text-xl font-semibold">Reauthentication</h2>
      </div>
      <div className="space-y-4 bg-gray-800/50 p-6 rounded-lg border border-gray-700">
        <p className="text-gray-300">
          If you need to refresh your Huawei FusionSolar authentication, you can do so here. 
          This will redirect you to Huawei's login page to reauthorize access to your installation data.
        </p>
        <ActionButton
          onClick={handleReauth}
          variant="primary"
          isLoading={isCreatingSession}
          loadingText="Preparing Authentication..."
          disabled={!publicKey}
        >
          Reauthorize Huawei Access
        </ActionButton>
      </div>
    </div>
  );
};

interface SystemInfo {
  time_utc_sec: number;
  temperature_celsius: number;
  processes_average: {
    last_1min: number;
    last_5min: number;
    last_15min: number;
  };
  uptime_seconds: number;
  memory_MB: {
    total: number;
    available: number;
    free: number;
    used: number;
    percent_used: number;
  };
}

const SystemInfoCard = ({ title, icon: Icon, children }: { 
  title: string | React.ReactNode; 
  icon: React.ComponentType<{ className?: string }>;
  children: React.ReactNode;
}) => (
  <div className="bg-[#2d2d2d] rounded-lg p-4 flex flex-col">
    {typeof title === 'string' ? (
      <div className="flex items-center gap-2 mb-3">
        <Icon className="w-5 h-5 text-green-500" />
        <h4 className="text-sm font-medium text-gray-300">{title}</h4>
      </div>
    ) : (
      <div className="mb-3">
        {title}
      </div>
    )}
    {children}
  </div>
);

const ProgressBar = ({ percentage, color }: { percentage: number; color: string }) => (
  <div className="w-full h-2 bg-gray-700 rounded-full overflow-hidden">
    <div 
      className={`h-full ${color} transition-all duration-500`}
      style={{ width: `${percentage}%` }}
    />
  </div>
);

const ProcessLoadBar = ({ load }: { load: number }) => {
  const getColor = (load: number) => {
    if (load < 0.5) return "bg-green-500";
    if (load < 0.8) return "bg-yellow-500";
    return "bg-red-500";
  };

  return (
    <ProgressBar 
      percentage={Math.min(load * 100, 100)} 
      color={getColor(load)} 
    />
  );
};

const SystemInfoDisplay = ({ systemInfo, gatewayId }: { systemInfo: SystemInfo; gatewayId: string }) => {
  const [showRebootDialog, setShowRebootDialog] = useState(false);
  const [isRebooting, setIsRebooting] = useState(false);

  const handleReboot = async () => {
    setIsRebooting(true);
    try {
      await GatewayMessageManager.getInstance().fetch(
        '/api/system/reboot',
        {
          method: 'POST'
        },
        {
          gatewayId,
          timeout: 5000 // Short timeout since we expect no response
        }
      );

      toast.success('Gateway is rebooting. This may take a few minutes.');
      
      // Keep showing the rebooting state for 30 seconds
      setTimeout(() => {
        setIsRebooting(false);
        setShowRebootDialog(false);
        toast.success('Gateway reboot initiated. The gateway will be unavailable for a few minutes.');
      }, 30000);
    } catch (error) {
      // Even if we get a timeout error, the reboot command might have gone through
      toast.success('Gateway reboot likely initiated. The gateway will be unavailable for a few minutes.');
      
      // Keep showing the rebooting state for 30 seconds
      setTimeout(() => {
        setIsRebooting(false);
        setShowRebootDialog(false);
      }, 30000);
    }
  };

  const formatUptime = (seconds: number) => {
    const days = Math.floor(seconds / (24 * 60 * 60));
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);

    return `${days}d ${hours}h ${minutes}m`;
  };

  const getTemperatureColor = (temp: number) => {
    if (temp < 45) return "text-green-500";
    if (temp < 60) return "text-yellow-500";
    return "text-red-500";
  };

  return (
    <div className="mt-4 space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold text-gray-100">System Information</h3>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Temperature and Uptime Card */}
        <div className="grid grid-cols-2 gap-4">
          <SystemInfoCard title="Temperature" icon={BeakerIcon}>
            <div className="flex items-baseline">
              <span className={`text-2xl font-bold ${getTemperatureColor(systemInfo.temperature_celsius)}`}>
                {systemInfo.temperature_celsius.toFixed(1)}
              </span>
              <span className="text-gray-400 ml-1">°C</span>
            </div>
          </SystemInfoCard>

          <SystemInfoCard title="Uptime" icon={ClockIcon}>
            <div className="text-xl font-bold text-gray-100">
              {formatUptime(systemInfo.uptime_seconds)}
            </div>
          </SystemInfoCard>
        </div>

        {/* Memory Usage Card */}
        <SystemInfoCard title="Memory Usage" icon={CircleStackIcon}>
          <div className="space-y-2">
            <ProgressBar 
              percentage={systemInfo.memory_MB.percent_used} 
              color={systemInfo.memory_MB.percent_used > 80 ? "bg-red-500" : "bg-green-500"} 
            />
            <div className="flex justify-between text-sm">
              <span className="text-gray-400">
                Used: {systemInfo.memory_MB.used.toFixed(0)} MB
              </span>
              <span className="text-gray-400">
                Total: {systemInfo.memory_MB.total.toFixed(0)} MB
              </span>
            </div>
          </div>
        </SystemInfoCard>

        {/* System Load Card */}
        <SystemInfoCard title="System Load" icon={CpuChipIcon}>
          <div className="space-y-3">
            <div className="space-y-1">
              <div className="flex justify-between text-sm">
                <span className="text-gray-400">1 min</span>
                <span className="text-gray-300">{systemInfo.processes_average.last_1min.toFixed(2)}</span>
              </div>
              <ProcessLoadBar load={systemInfo.processes_average.last_1min} />
            </div>

            <div className="space-y-1">
              <div className="flex justify-between text-sm">
                <span className="text-gray-400">5 min</span>
                <span className="text-gray-300">{systemInfo.processes_average.last_5min.toFixed(2)}</span>
              </div>
              <ProcessLoadBar load={systemInfo.processes_average.last_5min} />
            </div>

            <div className="space-y-1">
              <div className="flex justify-between text-sm">
                <span className="text-gray-400">15 min</span>
                <span className="text-gray-300">{systemInfo.processes_average.last_15min.toFixed(2)}</span>
              </div>
              <ProcessLoadBar load={systemInfo.processes_average.last_15min} />
            </div>
          </div>
        </SystemInfoCard>

        {/* System Reboot Card */}
        <SystemInfoCard title="System Reboot" icon={ExclamationTriangleIcon}>
          <div className="flex-1" />
          <div className="flex items-center justify-center mt-4">
            <button
              onClick={() => setShowRebootDialog(true)}
              className="w-48 bg-red-600 hover:bg-red-700 rounded-lg px-4 py-2.5 text-white font-medium transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex flex-row items-center justify-center gap-2"
              disabled={isRebooting}
            >
              <ExclamationTriangleIcon className={`w-6 h-6 ${isRebooting ? 'animate-spin' : ''}`} />
              <span className="text-sm">{isRebooting ? 'Rebooting...' : 'Reboot'}</span>
            </button>
          </div>
        </SystemInfoCard>
      </div>

      <div className="text-xs text-gray-500 mt-4">
        Last updated: {new Date(systemInfo.time_utc_sec * 1000).toLocaleString()}
      </div>

      {/* Reboot Confirmation Dialog */}
      {showRebootDialog && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-[#2d2d2d] rounded-lg p-6 max-w-md w-full space-y-4">
            <div className="flex items-center gap-3 text-red-500">
              <ExclamationTriangleIcon className="w-6 h-6" />
              <h4 className="text-lg font-semibold">Confirm Gateway Reboot</h4>
            </div>
            
            <div className="space-y-3 text-gray-300">
              <p>Are you sure you want to reboot this gateway? This will:</p>
              <ul className="list-disc list-inside space-y-1 text-sm">
                <li>Temporarily disconnect the gateway from the network</li>
                <li>Interrupt any ongoing operations or data collection</li>
                <li>Take several minutes to complete</li>
              </ul>
              <p className="text-sm text-yellow-500">
                Note: The gateway may be temporarily unavailable during the reboot process.
              </p>
            </div>

            <div className="flex justify-end gap-3 pt-4">
              <button
                onClick={() => setShowRebootDialog(false)}
                className="px-4 py-2 bg-gray-600 hover:bg-gray-700 text-white font-medium rounded-md transition-colors"
                disabled={isRebooting}
              >
                Cancel
              </button>
              <button
                onClick={handleReboot}
                className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white font-medium rounded-md transition-colors flex items-center gap-2"
                disabled={isRebooting}
              >
                {isRebooting ? (
                  <>
                    <ArrowPathIcon className="w-4 h-4 animate-spin" />
                    Rebooting...
                  </>
                ) : (
                  <>
                    <ArrowPathIcon className="w-4 h-4" />
                    Confirm Reboot
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const GatewayOverview = () => {
  const { connected } = useWallet();
  const { gateways, activeGateway, setActiveGateway } = useGateways();
  const { gatewayId } = useParams({ from: gatewayOverviewRoute });
  const [lastSeen, setLastSeen] = useState('');
  const { publicKey } = useWallet();
  const [systemInfo, setSystemInfo] = useState<SystemInfo | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (gatewayId && gateways) {
      const gateway = gateways.find(g => g.id === gatewayId);
      if (gateway) {
        setActiveGateway(gateway);
        // Clear system info when switching gateways
        setSystemInfo(null);
      }
    }
  }, [gatewayId, gateways, setActiveGateway]);

  useEffect(() => {
    const updateLastSeen = () => {
      if (activeGateway?.state?.timestamp) {
        setLastSeen(formatLastSeen(activeGateway.state.timestamp));
      }
    };

    updateLastSeen(); // Initial call
    const intervalId = setInterval(updateLastSeen, 60000); // Update every minute

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [activeGateway?.state?.timestamp]);

  const isCloudGateway = (id: string): boolean => {
    const cloudPrefixes = ['hse-', 'hdi-', 'hhw-'];
    return cloudPrefixes.some(prefix => id.toLowerCase().startsWith(prefix));
  };

  const handleGetSystemInfo = async () => {
    if (!activeGateway) return;

    setIsLoading(true);
    try {
      const response = await GatewayMessageManager.getInstance().fetch(
        '/api/system',
        {
          method: 'GET'
        },
        {
          gatewayId: activeGateway.id
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to get system info: ${response.status}`);
      }

      const data = await response.json<SystemInfo>();
      setSystemInfo(data);
    } catch (error) {
      toast.error('Failed to get system information');
      console.error('Error fetching system info:', error);
      // Clear system info on error
      setSystemInfo(null);
    } finally {
      setIsLoading(false);
    }
  };

  if (!connected || !activeGateway) return null;

  const formatPublicKey = (key: string) => {
    if (!key) return "not in backend";
    return `${key.slice(0, 4)}...${key.slice(-4)}`;
  };

  return (
    <ContentFramework>
      <PageContainer>
        <GatewayPageHeader 
          gatewayName={activeGateway.name}
        />
        <h1 className="text-4xl font-bold mb-6">Overview</h1>

        {/* Gateway Information */}
        <div className="w-full">
          <div>
            <h2 className="text-base font-bold leading-7">
              Energy Gateway Information
            </h2>
          </div>
          <div className="mt-5">
              <InfoRow
                label={gatewayInfo.name.label}
                value={activeGateway.name}
                isCopyable={true}
                tooltip={gatewayInfo.name.tooltip}
              />
              <InfoRow
                label="Type"
                value={isHardwareGateway(activeGateway) ? "Hardware Gateway" : "Cloud Gateway"}
                displayValue={
                  <div className="flex items-center gap-2">
                    {isHardwareGateway(activeGateway) ? (
                      <GatewayIcon state="none" className="w-5 h-5" />
                    ) : (
                      <CloudGatewayIcon state="none" className="w-5 h-5" />
                    )}
                    <span>{isHardwareGateway(activeGateway) ? "Hardware Gateway" : "Cloud Gateway"}</span>
                  </div>
                }
                tooltip="The type of gateway - either a physical hardware gateway or a cloud-based gateway"
              />
              <InfoRow
                label={gatewayInfo.serial.label}
                value={activeGateway.serial}
                displayValue={
                  <a
                    href={`https://explorer.sourceful.energy/egw/${activeGateway.serial}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 hover:bg-white/10 rounded-md transition-colors px-2 py-1"
                  >
                    <span>{activeGateway.serial}</span>
                    <ArrowTopRightOnSquareIcon className="w-4 h-4" />
                  </a>
                }
                isCopyable={true}
                tooltip={gatewayInfo.serial.tooltip}
              />
              {isHardwareGateway(activeGateway) && (
                <InfoRow
                  label={gatewayInfo.publicKey.label}
                  value={activeGateway.publicKey}
                  displayValue={formatPublicKey(activeGateway.publicKey)}
                  isCopyable={true}
                  tooltip={gatewayInfo.publicKey.tooltip}
                />
              )}
              <InfoRow
                label={gatewayInfo.h3Index.label}
                value={activeGateway.h3Index}
                displayValue={
                  <a
                    href={`https://explorer.sourceful.energy/hex/${activeGateway.h3Index}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 hover:bg-white/10 rounded-md transition-colors px-2 py-1"
                  >
                    <span>{activeGateway.h3Index}</span>
                    <ArrowTopRightOnSquareIcon className="w-4 h-4" />
                  </a>
                }
                isCopyable={true}
                tooltip={gatewayInfo.h3Index.tooltip}
              />
              {activeGateway.state?.timestamp && (
                <InfoRow
                  label={gatewayInfo.lastSeen.label}
                  value={activeGateway.state.timestamp.toString()}
                  displayValue={
                    <span className={`${Date.now() - activeGateway.state.timestamp > 15 * 60 * 1000 ? 'text-red-500' : ''}`}>
                      {lastSeen}
                    </span>
                  }
                  tooltip={gatewayInfo.lastSeen.tooltip}
                />
              )}
              {isHardwareGateway(activeGateway) && (
                <>
                  <InfoRow
                    label={gatewayInfo.uptime.label}
                    value={activeGateway.state?.status?.uptime?.toString()}
                    displayValue={activeGateway.state?.status?.uptime ? formatUptime(activeGateway.state.status.uptime) : 'Unknown'}
                    tooltip={gatewayInfo.uptime.tooltip}
                  />
                  <InfoRow
                    label={gatewayInfo.firmware.label}
                    value={activeGateway.state?.status?.version}
                    displayValue={activeGateway.state?.status?.version || 'Unknown'}
                    tooltip={gatewayInfo.firmware.tooltip}
                  />
                </>
              )}
          </div>
        </div>

        {/* DER Section */}
        <DerSection ders={activeGateway.ders} />

        {/* Reauthentication Section - only for Huawei cloud gateways */}
        {activeGateway.id.startsWith('hhw-') && (
          <ReauthenticationSection 
            gatewayId={activeGateway.id} 
            publicKey={publicKey?.toString() || null} 
          />
        )}

        {!isCloudGateway(activeGateway.id) && (
          <>
            <div className="h-px bg-gray-200/10 my-8" />
            <div className="mt-6">
              <button
                onClick={handleGetSystemInfo}
              disabled={isLoading}
              className="px-4 py-2 bg-green-600 hover:bg-green-700 text-white font-medium rounded-md transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? 'Loading...' : 'Get System Info'}
            </button>

            {systemInfo && (
              <SystemInfoDisplay systemInfo={systemInfo} gatewayId={activeGateway.id} />
            )}
            </div>
          </>
        )}
      </PageContainer>
    </ContentFramework>
  );
};

export default GatewayOverview; 